// App.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Tile from "./Tile";
import Header from "./Header";
import Footer from "./Footer";
import Login from "./Login";
import Menu from "./Menu";
import Prompter from "./Prompter";
import Timer from "./Timer";
import Turtle from "./Turtle"; // Import the new Turtle component
import ManagePrompts from "./ManagePrompts"; // Import the new ManagePrompts component
import PrivateRoute from "./PrivateRoute";
import "./App.css";

// Public Home component (your portfolio)
function Home() {
  const tilesData = [
    {
      title: "Primary Skills",
      tile_description: "This is tile 1",
      long_description: `My primary skillset includes, but not limited to: \n\n - **Distributed Systems** 
                          \n\n - **Python3** \n\n - **.NET Core** \n\n - **Angular/React** \n\n - Azure
                          \n\n - Redis \n\n - Docker \n\n - Celery`,
      link: "https://google.com",
    },
    {
      title: "Other Skills",
      tile_description: "This is tile 2",
      long_description:
        "- With a comprehensive understanding of **Data Structures and Algorithms**, I have actively participated in a variety of competitive coding contests, including showcasing my problem-solving abilities and code optimization skills. \n\n - Distributed systems require a distributed effort. As a **Senior Software Engineer**, I bring together the technical and the human side, facilitating efficient collaboration across various functions. \n\n -  I don't just write code; I foster connections. My communication skills help me bridge the gap between design, development, and delivery, turning great ideas into even greater software.",
      link: "https://google.com",
    },
    {
      title: "Social Media",
      tile_description: "",
      long_description:
        "- **[LinkedIn](https://linkedin.com/in/sachinchepuri)**\n\n - **[GitHub](http://github.com/sachinsfo)**",
      link: "https://google.com",
    },
    {
      title: "Education",
      tile_description: "This is tile 4",
      long_description:
        "- **Master's** in Computer Engineering (University of North Texas, USA) \n\n - **Bachelor's** in Electronics (Kakatiya Institute of Technology & Sciences, India)",
      link: "https://google.com",
    },
    {
      title: "Projects",
      tile_description: "- ",
      long_description: "",
      // long_description: `My personal projects: \n\n - Designed a system that detects if a twitch streamers are online and posts their LIVE notifications to discord. **Listens to hundreds of twitch streams!** Tech used: Python3, Celery, Redis & Docker.`,
      link: "https://google.com",
    },
    {
      title: "DCSS (Contract)",
      tile_description: "This is tile 6",
      long_description:
        "Aug 2015 - Dec 2019 \n\n - Implemented a brand new application to catpure user informtion and their child support case. \n\n - Implemented a new Microservice to handle License, Suspension and Revocation cases. \n\n - Implement an advanced search algorithm to search millions of child support cases. \n\n - Implemented a new database migration from SQL to GraphQL",
      link: "https://google.com",
    },
    {
      title: "Tesla (Contract)",
      tile_description: "1",
      long_description:
        "Jan 2020 - Dec 2021\n\n - Implemented a new micro service to handle new employee onboarding. \n\n - Implemented features to handle customer orders for **Tesla Energy** \n\n - Implemented complex stored procedures to regulate the flow of data from an older system to a newer system.",
      link: "https://google.com",
    },
    {
      title: "21st Mortgage (Contract)",
      tile_description: "This is tile 2",
      long_description:
        "Jan 2022 - Present\n\n - Implemented **document generation** and capture functionality while talking to a different service on the distributed system.\n\n - Implemented a **dynamic grid** that has cut the development time from months to days \n\n - Implemented **custom directives** to handle user data efficiently \n\n - Implemented a number of new components to sanitize and save user data like External Service Provider, Contact Details etc. \n\n - Built a **custom plugin/directive** to handle autocomplete in the custom IDE and tag users.",
      link: "https://google.com",
    },
    {
      title: "Certifications",
      tile_description: "This is tile 3",
      long_description: `Coursera\n\n - AI For Everyone (Andrew Ng)`,
      link: "https://google.com",
    },
    {
      title: "Testimonials",
      tile_description: "This is tile 4",
      long_description:
        "Sowmiya Ramkarthik \n\n - Sachin is an outstanding .NET Developer. He has received multiple recognition at the department level for his knowledge and helping other team members in solving technical issues. He was one of the key developers to work on piloting GIT projects. He also **led a group of developers** in creating a solution similar to chat-bot using angular and python. It was a pleasure working with an energetic and technically competent professional like Sachin.\n\nSwetha Miriyala\n\n - Sachin is a self-driven and very energetic engineer. He has designed and developed a custom chat application for developers when he saw a need for collaboration. He **architected the app** and successfully led with a group of developers. Highly recommend, he is a quick learner and will be a great asset to any project.",
      link: "https://google.com",
    },
    // {
    //   title: "Tile 15",
    //   tile_description: "This is tile 5",
    //   long_description: "long description long description",
    //   link: "https://google.com",
    // },
    // {
    //   title: "Tile 16",
    //   tile_description: "This is tile 6",
    //   long_description: "long **bold description** long description",
    //   link: "https://google.com",
    // },
    // {
    //   title: "Tile 14",
    //   tile_description: "This is tile 4",
    //   long_description: "long description long description",
    //   link: "https://google.com",
    // },
    // {
    //   title: "Tile 15",
    //   tile_description: "This is tile 5",
    //   long_description: "long description long description",
    //   link: "https://google.com",
    // },
    // {
    //   title: "Tile 16",
    //   tile_description: "This is tile 6",
    //   long_description: "long **bold description** long description",
    //   link: "https://google.com",
    // },
  ];

  return (
    <div className="App">
      <Header
        myname="Sachin Chepuri"
        myemail="SachinChepuri2@gmail.com"
        myphone="(925) 460-7717"
        occupation="Software Engineer (Ex-Tesla)"
        // No showLogout prop passed, so logout button is hidden.
      />
      <div style={{ margin: "20px 0" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <a
            href="/prompter"
            style={{
              width: "120px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#f0f0f0",
              textDecoration: "none",
              background: "rgba(0, 0, 0, 0.25)",
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
              backdropFilter: "blur(4px)",
              WebkitBackdropFilter: "blur(4px)",
              borderRadius: "10px",
              border: "1px solid rgba(255, 255, 255, 0.18)",
              transition: "transform 0.3s ease-in-out",
              fontWeight: "bold",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#333";
              e.currentTarget.style.transform = "scale(1.1)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.25)";
              e.currentTarget.style.transform = "scale(1)";
            }}
          >
            Prompter
          </a>
          <a
            href="/timer"
            style={{
              width: "120px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#f0f0f0",
              textDecoration: "none",
              background: "rgba(0, 0, 0, 0.25)",
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
              backdropFilter: "blur(4px)",
              WebkitBackdropFilter: "blur(4px)",
              borderRadius: "10px",
              border: "1px solid rgba(255, 255, 255, 0.18)",
              transition: "transform 0.3s ease-in-out",
              fontWeight: "bold",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#333";
              e.currentTarget.style.transform = "scale(1.1)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.25)";
              e.currentTarget.style.transform = "scale(1)";
            }}
          >
            Timer
          </a>
          {/* Add the new Turtle button */}
          <a
            href="/turtle" // Changed link to /turtle
            style={{
              width: "120px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#f0f0f0",
              textDecoration: "none",
              background: "rgba(0, 0, 0, 0.25)",
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
              backdropFilter: "blur(4px)",
              WebkitBackdropFilter: "blur(4px)",
              borderRadius: "10px",
              border: "1px solid rgba(255, 255, 255, 0.18)",
              transition: "transform 0.3s ease-in-out",
              fontWeight: "bold",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#333";
              e.currentTarget.style.transform = "scale(1.1)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.25)";
              e.currentTarget.style.transform = "scale(1)";
            }}
          >
            Turtle
          </a>
        </div>
      </div>
      <div className="tiles-container">
        {tilesData.map((tile, index) => (
          <Tile
            key={index}
            title={tile.title}
            tile_description={tile.tile_description}
            long_description={tile.long_description}
            link={tile.link}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/prompter" element={<Prompter />} />
      <Route path="/timer" element={<Timer />} />
      <Route path="/turtle" element={<Turtle />} />
      <Route
        path="/manage-prompts"
        element={
          <PrivateRoute>
            <ManagePrompts />
          </PrivateRoute>
        }
      />
      <Route
        path="/menu"
        element={
          <PrivateRoute>
            <Menu />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
