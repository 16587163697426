import React, { useRef, useEffect, useState, useCallback } from "react";
import "./Turtle.css"; // Ensure this CSS file exists and is styled

const Turtle = () => {
  const canvasRef = useRef(null);
  const [program, setProgram] = useState(""); // Input field for multiple commands
  const [history, setHistory] = useState([]); // Display executed commands/program
  const [error, setError] = useState(null); // State for error messages
  const [drawingElements, setDrawingElements] = useState([]); // Stores lines, dots, fills, stamps
  const drawingElementsRef = useRef([]); // Sync ref
  const [canvasBgColor, setCanvasBgColor] = useState("#f0f0f0"); // Canvas background

  // State history for undo functionality
  const stateHistory = useRef([]);

  // Default turtle state
  const getDefaultTurtleState = () => ({
    x: 300,
    y: 200,
    angle: 0, // 0 degrees: Up
    penDown: true,
    color: "black",
    lineWidth: 2,
    isVisible: true,
    isFilling: false,
    fillPath: [], // Points for the current fill polygon
    speed: 6, // Placeholder for speed (1-10, 0=fastest) - drawing is instant though
  });

  // The turtle's current state
  const turtleState = useRef(getDefaultTurtleState());

  // Get the canvas context
  const getContext = useCallback(() => {
    const canvas = canvasRef.current;
    return canvas ? canvas.getContext("2d") : null;
  }, []);

  // --- Drawing Functions ---

  // Clear the entire drawing area
  const clearDrawingArea = useCallback(
    (ctx) => {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.fillStyle = canvasBgColor;
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    },
    [canvasBgColor]
  );

  // Draw the turtle shape
  const drawTurtleShape = useCallback((ctx, x, y, angle, color) => {
    // Rotate angle: 0=North, 90=East, 180=South, 270=West
    // Canvas rotation: 0=East, 90=South, 180=West, 270=North
    // The turtle shape points North (towards -Y) when canvas rotation is 0.
    // Rotate angle: 0=North, 90=East, 180=South, 270=West
    // Canvas rotation: 0=East, 90=South, 180=West, 270=North (-PI/2)
    // The turtle shape points North (towards -Y) when canvas rotation is 0.
    // To make the turtle point North visually when angle is 0, canvas rotation needs to be -PI/2.
    // To make the turtle point East visually when angle is 90, canvas rotation needs to be 0.
    // Formula: canvas_rad = angle_rad - PI/2
    const rad = (angle * Math.PI) / 180; // Standard conversion; with default angle 270 the turtle points left
    ctx.save();
    ctx.translate(x, y);
    ctx.rotate(rad);
    ctx.beginPath();
    ctx.moveTo(0, -10); // Tip
    ctx.lineTo(5, 5); // Bottom right
    ctx.lineTo(-5, 5); // Bottom left
    ctx.closePath();
    ctx.strokeStyle = color;
    ctx.lineWidth = 1; // Always draw turtle outline thin
    ctx.stroke();
    ctx.restore();
  }, []);

  // Redraw entire canvas: background, drawing elements, and the turtle
  const redraw = useCallback(() => {
    const ctx = getContext();
    if (!ctx) return;

    clearDrawingArea(ctx);

    // Draw all elements (lines, fills, dots, stamps)
    drawingElementsRef.current.forEach((element) => {
      ctx.save(); // Save context state for each element
      ctx.strokeStyle = element.color || "black";
      ctx.lineWidth = element.lineWidth || 1;
      ctx.fillStyle = element.color || "black"; // For fills and dots

      switch (element.type) {
        case "line":
          ctx.beginPath();
          ctx.moveTo(element.startX, element.startY);
          ctx.lineTo(element.endX, element.endY);
          ctx.stroke();
          break;
        case "dot":
          ctx.beginPath();
          ctx.arc(element.x, element.y, element.size / 2, 0, 2 * Math.PI);
          ctx.fill();
          break;
        case "fill":
          if (element.path && element.path.length > 1) {
            ctx.beginPath();
            ctx.moveTo(element.path[0].x, element.path[0].y);
            element.path.slice(1).forEach((p) => ctx.lineTo(p.x, p.y));
            ctx.closePath();
            ctx.fill();
          }
          break;
        case "stamp":
          // Redraw the turtle shape at the stamped location
          drawTurtleShape(
            ctx,
            element.x,
            element.y,
            element.angle,
            element.color
          );
          break;
        case "circle":
          ctx.beginPath();
          ctx.arc(element.x, element.y, element.radius, 0, 2 * Math.PI);
          ctx.stroke();
          break;
        case "arc":
          {
            const startAngleRad = ((element.startAngle - 90) * Math.PI) / 180;
            const endAngleRad =
              startAngleRad + (element.extent * Math.PI) / 180;
            ctx.beginPath();
            ctx.arc(
              element.centerX,
              element.centerY,
              element.radius,
              startAngleRad,
              endAngleRad,
              element.extent < 0
            );
            ctx.stroke();
          }
          break;
        default:
          console.warn("Unknown drawing element type:", element.type);
      }
      ctx.restore(); // Restore context state
    });

    // Draw the current turtle if visible
    if (turtleState.current.isVisible) {
      drawTurtleShape(
        ctx,
        turtleState.current.x,
        turtleState.current.y,
        turtleState.current.angle,
        turtleState.current.penDown ? "green" : "red" // Color indicates pen state
      );
    }
  }, [getContext, clearDrawingArea, drawTurtleShape]);

  // Keep drawingElementsRef in sync with drawingElements state
  useEffect(() => {
    drawingElementsRef.current = drawingElements;
    redraw(); // Redraw whenever elements change
  }, [drawingElements, redraw]);

  // Keep canvas background in sync
  useEffect(() => {
    redraw();
  }, [canvasBgColor, redraw]);

  // Initial draw on mount
  useEffect(() => {
    redraw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Redraw only once on mount initially

  // --- State Management & Undo ---

  // Save the current state (turtle + drawing elements)
  const saveState = useCallback(() => {
    const currentState = {
      turtle: { ...turtleState.current },
      elements: [...drawingElementsRef.current],
      bgColor: canvasBgColor,
    };
    // Limit history size if needed (e.g., 100 steps)
    if (stateHistory.current.length > 100) {
      stateHistory.current.shift(); // Remove oldest state
    }
    stateHistory.current.push(currentState);
  }, [canvasBgColor]);

  // Restore the previous state
  const undoLastAction = useCallback(() => {
    if (stateHistory.current.length > 0) {
      const prevState = stateHistory.current.pop();
      turtleState.current = prevState.turtle;
      // Update React state to trigger redraw
      setDrawingElements(prevState.elements);
      setCanvasBgColor(prevState.bgColor);
      setError(null); // Clear any previous error on undo
    } else {
      setError("Nothing to undo.");
    }
  }, []);

  // --- Command Parsing and Execution ---

  // Parses a block of text into a list of command objects
  const parseProgram = (programText) => {
    const lines = programText
      .split(/[\n;]/)
      .map((line) => line.trim())
      .filter((line) => line && !line.startsWith("#")); // Split by newline or semicolon, trim, remove empty lines and comments
    const commands = [];
    let i = 0;
    while (i < lines.length) {
      const line = lines[i];
      const parts = line
        .toLowerCase()
        .split(/\s+/)
        .filter((p) => p); // Split by whitespace, remove empty parts
      // Use regex for repeat to allow optional space before '['
      const repeatMatch = line.toLowerCase().match(/^repeat\s+(\d+)\s*(\[.*)/);

      if (repeatMatch) {
        // It's a repeat command
        const repeatCount = parseInt(repeatMatch[1], 10);
        let remainingLine = repeatMatch[2]; // Starts with '['

        if (isNaN(repeatCount)) {
          throw new Error(
            `Invalid repeat count at line ${i + 1}: ${line}. Expected a number.`
          );
        }

        // Handle the block content starting from remainingLine
        let bracketLevel = 0;
        const repeatBlockLines = [];
        let firstBlockLine = "";

        // Check if the opening bracket has content immediately after it on the same line
        if (remainingLine.startsWith("[")) {
          // Count initial brackets on this line segment
          bracketLevel += (remainingLine.match(/\[/g) || []).length;
          bracketLevel -= (remainingLine.match(/\]/g) || []).length;

          // Extract content after the first '[' and before the last ']' if closed on same line
          if (bracketLevel === 0 && remainingLine.length > 1) {
            // Closed on the same line
            const firstBracketIndex = remainingLine.indexOf("[");
            const lastBracketIndex = remainingLine.lastIndexOf("]");
            if (lastBracketIndex > firstBracketIndex) {
              firstBlockLine = remainingLine
                .substring(firstBracketIndex + 1, lastBracketIndex)
                .trim();
            } else {
              // Handle cases like 'repeat 1 []'
              firstBlockLine = "";
            }
          } else if (remainingLine.length > 1) {
            // Still open, content after '['
            firstBlockLine = remainingLine.substring(1).trim();
          }
          // Add the extracted content if any
          if (firstBlockLine) {
            repeatBlockLines.push(firstBlockLine);
          }
        } else {
          // Should not happen with the regex match, but as a safeguard:
          throw new Error(
            `Invalid repeat format near line ${i + 1}: ${line}. Missing '['.`
          );
        }

        let j = i + 1;
        // Only loop through subsequent lines if the block wasn't closed on the first line
        while (bracketLevel > 0 && j < lines.length) {
          const blockLine = lines[j];
          // Count brackets accurately on this line
          const openBrackets = (blockLine.match(/\[/g) || []).length;
          const closeBrackets = (blockLine.match(/\]/g) || []).length;
          bracketLevel += openBrackets;
          bracketLevel -= closeBrackets;

          if (bracketLevel === 0) {
            // Found the closing bracket on this line
            const closingBracketIndex = blockLine.lastIndexOf("]");
            const lineContent = blockLine
              .substring(0, closingBracketIndex)
              .trim();
            if (lineContent) {
              // Only add if there's content before the bracket
              repeatBlockLines.push(lineContent);
            }
            // Handle potential commands after ']' on the same line if needed (currently ignored)
            break;
          } else if (bracketLevel < 0) {
            // More closing than opening brackets encountered before reaching level 0
            throw new Error(
              `Mismatched brackets: Too many ']' found near line ${j + 1}`
            );
          } else {
            // Still inside the block, add the whole line
            repeatBlockLines.push(blockLine);
          }
          j++;
        }

        if (bracketLevel !== 0) {
          throw new Error(
            `Mismatched brackets for repeat command starting at line ${
              i + 1
            }. Block not properly closed.`
          );
        }

        const repeatBlockText = repeatBlockLines.join("\n");
        const nestedCommands = parseProgram(repeatBlockText); // Recursively parse the block
        commands.push({
          name: "repeat",
          count: repeatCount,
          commands: nestedCommands,
          line: i + 1,
        });
        i = j + 1; // Move past the repeat block
      } else {
        // It's a simple command (or empty/comment line)
        const parts = line
          .toLowerCase()
          .split(/\s+/)
          .filter((p) => p);
        const commandName = parts[0];
        if (commandName) {
          // Ensure it's not an empty line after filtering
          commands.push({
            name: commandName,
            args: parts.slice(1),
            line: i + 1,
          });
        }
        i++;
      }
    }
    return commands;
  };

  // Executes a list of parsed command objects
  const executeParsedCommands = (parsedCommands) => {
    for (const cmd of parsedCommands) {
      // Before executing, save state for potential undo
      // Optimization: Only save state if the command modifies it (most do)
      if (
        ![
          "rt",
          "lt",
          "seth",
          "speed",
          "setcolor",
          "setwidth",
          "pu",
          "pd",
          "ht",
          "st",
        ].includes(cmd.name)
      ) {
        // For commands that don't immediately draw but change state, saving might be optional
        // But safer to save for most. Exclude pure state changes if performance is critical.
      }
      // saveState(); // Moved inside executeSingleCommand, conditionally

      try {
        executeSingleCommand(cmd);
      } catch (e) {
        setError(
          `Error executing command at line ${cmd.line || "N/A"} (${
            cmd.name
          }): ${e.message}`
        );
        // Stop execution on error
        return false; // Indicate failure
      }
    }
    return true; // Indicate success
  };

  // Executes a single parsed command object
  const executeSingleCommand = (cmd) => {
    const { name } = cmd;
    const args = cmd.args || []; // Ensure args is always an array, default to empty

    // *** Don't save state if the command is 'undo' ***
    // Also handles saving state for all other commands before they execute
    if (name !== "undo") {
      saveState();
    }

    const {
      x: currentX,
      y: currentY,
      angle: currentAngle,
      penDown,
      color,
      lineWidth,
      isFilling,
      fillPath,
    } = turtleState.current;

    let value1 =
      args.length > 0
        ? args[0] === "random"
          ? Math.random() * 100
          : parseFloat(args[0])
        : null; // Allow 'random' for distance/angle?
    let value2 = args.length > 1 ? parseFloat(args[1]) : null;
    let value3 = args.length > 2 ? args[2] : null; // For color names/values

    // --- Argument Validation ---
    const expectArgs = (count, types = []) => {
      if (args.length < count)
        throw new Error(
          `Command '${name}' expects at least ${count} argument(s).`
        );
      for (let k = 0; k < types.length; k++) {
        if (types[k] === "number" && isNaN(parseFloat(args[k]))) {
          throw new Error(
            `Command '${name}' expects a numeric value for argument ${
              k + 1
            }. Got '${args[k]}'.`
          );
        }
        if (types[k] === "color" && !isValidColor(args[k])) {
          // Basic check, could be more robust (regex for hex, known names)
          console.warn(
            `Potentially invalid color '${args[k]}' for command '${name}'.`
          );
        }
        // Add more type checks if needed
      }
    };

    const isValidColor = (strColor) => {
      // Very basic check: allows named colors, hex, rgb, rgba
      return typeof strColor === "string";
    };

    // --- Command Implementation ---
    let newX = currentX;
    let newY = currentY;
    let newAngle = currentAngle;
    let newPenDown = penDown;
    let newColor = color;
    let newLineWidth = lineWidth;
    let newIsVisible = turtleState.current.isVisible;
    let newIsFilling = isFilling;
    let newFillPath = [...fillPath]; // Copy path

    // Angle for movement: 0=North, 90=East, 180=South, 270=West
    // Math functions use: 0=East, 90=North, 180=West, 270=South
    // So, math angle = (90 - turtle angle) % 360
    const rad = ((90 - currentAngle) * Math.PI) / 180; // Correct angle for trig functions

    switch (name) {
      // Movement
      case "fd":
      case "forward":
        expectArgs(1, ["number"]);
        newX += value1 * Math.cos(rad);
        newY -= value1 * Math.sin(rad); // Correct Y direction (subtract for up)
        if (penDown) {
          const lineElement = {
            type: "line",
            startX: currentX,
            startY: currentY,
            endX: newX,
            endY: newY,
            color,
            lineWidth,
          };
          setDrawingElements((prev) => [...prev, lineElement]);
        }
        if (isFilling) newFillPath.push({ x: newX, y: newY });
        break;
      case "bk":
      case "backward":
        expectArgs(1, ["number"]);
        newX -= value1 * Math.cos(rad);
        newY += value1 * Math.sin(rad); // Correct Y direction (add for down)
        if (penDown) {
          const lineElement = {
            type: "line",
            startX: currentX,
            startY: currentY,
            endX: newX,
            endY: newY,
            color,
            lineWidth,
          };
          setDrawingElements((prev) => [...prev, lineElement]);
        }
        if (isFilling) newFillPath.push({ x: newX, y: newY });
        break;
      case "rt":
      case "right":
        expectArgs(1, ["number"]);
        newAngle = (currentAngle + value1) % 360;
        break;
      case "lt":
      case "left":
        expectArgs(1, ["number"]);
        newAngle = (currentAngle - value1 + 360) % 360;
        break;
      case "goto":
        expectArgs(2, ["number", "number"]);
        newX = value1;
        newY = value2;
        if (isFilling) newFillPath.push({ x: newX, y: newY }); // Add point even if pen is up during fill
        // Note: goto doesn't draw a line by default, even if pen is down. Matches traditional turtle.
        break;
      case "home":
        ({ x: newX, y: newY, angle: newAngle } = getDefaultTurtleState()); // Reset position/angle
        if (isFilling) newFillPath.push({ x: newX, y: newY });
        break;
      case "seth":
      case "setheading":
        expectArgs(1, ["number"]);
        newAngle = value1 % 360;
        break;

      // Pen Control
      case "pu":
      case "penup":
        newPenDown = false;
        break;
      case "pd":
      case "pendown":
        newPenDown = true;
        break;
      case "setcolor":
      case "color":
        expectArgs(1, ["color"]);
        newColor = args[0]; // Use the raw argument as color string
        break;
      case "setwidth":
      case "width":
      case "pensize":
        expectArgs(1, ["number"]);
        if (value1 <= 0) throw new Error("Width must be positive.");
        newLineWidth = value1;
        break;

      // Appearance & Drawing
      case "ht": // Hide Turtle
        newIsVisible = false;
        break;
      case "st": // Show Turtle
        newIsVisible = true;
        break;
      case "dot":
        {
          let dotSize = 5; // Default size
          let dotColor = color; // Default to current pen color
          if (args.length > 0) {
            expectArgs(1, ["number"]);
            dotSize = value1;
          }
          if (args.length > 1) {
            expectArgs(2, ["number", "color"]);
            dotColor = args[1];
          }
          if (dotSize <= 0) throw new Error("Dot size must be positive.");
          const dotElement = {
            type: "dot",
            x: currentX,
            y: currentY,
            size: dotSize,
            color: dotColor,
          };
          setDrawingElements((prev) => [...prev, dotElement]);
        }
        break;
      case "stamp":
        const stampElement = {
          type: "stamp",
          x: currentX,
          y: currentY,
          angle: currentAngle,
          color: color,
        };
        setDrawingElements((prev) => [...prev, stampElement]);
        break;
      case "circle":
        expectArgs(1, ["number"]);
        if (value1 <= 0) throw new Error("Circle radius must be positive.");
        // Center of circle is radius distance to the "left" of the turtle
        const circleCenterX = currentX + value1 * Math.cos(rad - Math.PI / 2);
        const circleCenterY = currentY + value1 * Math.sin(rad - Math.PI / 2);
        if (penDown) {
          const circleElement = {
            type: "circle",
            x: circleCenterX,
            y: circleCenterY,
            radius: value1,
            color,
            lineWidth,
          };
          setDrawingElements((prev) => [...prev, circleElement]);
        }
        // Note: Drawing a circle doesn't change the turtle's position or heading in standard turtle graphics
        break;
      case "arc":
        expectArgs(2, ["number", "number"]); // angle, radius
        const extent = value1;
        const radius = value2;
        if (radius <= 0) throw new Error("Arc radius must be positive.");

        const turn = extent / 2;
        const arcRad = (extent * Math.PI) / 180;
        const step = radius * 2 * Math.sin(arcRad / 2); // Chord length

        // Calculate center of the arc circle (similar to circle)
        const arcCenterX = currentX + radius * Math.cos(rad - Math.PI / 2);
        const arcCenterY = currentY + radius * Math.sin(rad - Math.PI / 2);

        // Calculate start angle relative to canvas (0 = East)
        const startAngleDeg =
          (Math.atan2(currentY - arcCenterY, currentX - arcCenterX) * 180) /
          Math.PI;

        if (penDown) {
          const arcElement = {
            type: "arc",
            centerX: arcCenterX,
            centerY: arcCenterY,
            radius: radius,
            startAngle: startAngleDeg + 90, // Adjust for 0 = North turtle heading
            extent: extent, // Angle extent
            color,
            lineWidth,
          };
          setDrawingElements((prev) => [...prev, arcElement]);
        }

        // Move turtle to the end of the arc
        // Turn half the arc angle
        const intermediateAngle = (currentAngle + turn + 360) % 360;
        const intermediateRad = ((intermediateAngle - 90) * Math.PI) / 180;
        // Move along the chord
        newX = currentX + step * Math.cos(intermediateRad);
        newY = currentY + step * Math.sin(intermediateRad);
        // Turn the other half
        newAngle = (currentAngle + extent + 360) % 360;

        if (isFilling) newFillPath.push({ x: newX, y: newY }); // Approximate arc end for fill path
        break;

      // Fill Control
      case "beginfill":
      case "begin_fill":
        if (isFilling)
          throw new Error("Already filling. Call 'endfill' first.");
        newIsFilling = true;
        newFillPath = [{ x: currentX, y: currentY }]; // Start path at current location
        break;
      case "endfill":
      case "end_fill":
        if (!isFilling) throw new Error("Not filling. Call 'beginfill' first.");
        if (newFillPath.length > 1) {
          const fillElement = { type: "fill", path: newFillPath, color: color }; // Use current color for fill
          setDrawingElements((prev) => [...prev, fillElement]);
        }
        newIsFilling = false;
        newFillPath = [];
        break;

      // Screen Control
      case "clr":
      case "cs":
      case "clear":
      case "clearscreen":
        // Reset turtle state completely
        turtleState.current = getDefaultTurtleState();
        // Clear drawing elements and history
        setDrawingElements([]);
        stateHistory.current = []; // Clear undo history too
        // Reset background color? Optional, maybe keep it. Let's keep it for now.
        // setCanvasBgColor("#f0f0f0");
        redraw(); // Force redraw immediately
        return; // Exit early as state is fully reset
      case "setbgcolor":
      case "bgcolor":
        expectArgs(1, ["color"]);
        setCanvasBgColor(args[0]); // Update React state for background
        // No need to save state here, background is handled separately
        break;

      // Control Flow & Misc
      case "repeat": // Handled by executeParsedCommands loop
        if (cmd.commands && cmd.count > 0) {
          for (let k = 0; k < cmd.count; k++) {
            const success = executeParsedCommands(cmd.commands); // Execute nested block
            if (!success)
              throw new Error(
                `Error occurred within repeat block (iteration ${k + 1})`
              ); // Propagate error
          }
        }
        break; // Don't update state directly for repeat itself
      case "undo":
        undoLastAction();
        return; // Exit early, state is restored by undo
      case "speed":
        expectArgs(1, ["number"]);
        if (value1 < 0 || value1 > 10)
          throw new Error("Speed must be between 0 (fastest) and 10.");
        turtleState.current.speed = value1; // Store speed, though it doesn't affect instant drawing
        break; // Only update speed state

      default:
        throw new Error(`Unknown command: ${name}`);
    }

    // Update the turtle state after command execution (if not returned early)
    turtleState.current = {
      x: newX,
      y: newY,
      angle: newAngle,
      penDown: newPenDown,
      color: newColor,
      lineWidth: newLineWidth,
      isVisible: newIsVisible,
      isFilling: newIsFilling,
      fillPath: newFillPath,
      speed: turtleState.current.speed, // Keep existing speed unless changed
    };

    // No explicit redraw here, useEffect handles it when drawingElements changes
    // However, for state changes that don't add elements (like rt, lt, pu, pd, color),
    // we might need to trigger a redraw manually if the turtle's appearance changes.
    if (
      ["rt", "lt", "seth", "pu", "pd", "ht", "st", "home", "goto"].includes(
        name
      )
    ) {
      redraw(); // Manually trigger redraw for turtle appearance/position changes
    }
  };

  // --- Event Handlers ---
  const handleInputChange = (e) => {
    setProgram(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setError(null); // Clear previous errors
    const programToRun = program.trim();

    if (programToRun) {
      // Clear previous state history before starting new program execution
      // Or maybe keep it? Let's keep it for now to allow undo across runs.
      // stateHistory.current = [];

      // Save initial state before executing the whole program
      saveState();

      try {
        const parsedCommands = parseProgram(programToRun);
        const success = executeParsedCommands(parsedCommands);
        if (success) {
          setHistory((prev) => [
            ...prev,
            { type: "program", content: programToRun },
          ]);
          // Don't clear the input field, user might want to modify and rerun
          // setProgram("");
        } else {
          // Error already set by executeParsedCommands/executeSingleCommand
          // Maybe rollback to state before execution?
          undoLastAction(); // Rollback the failed program execution
          setError(
            (prevError) =>
              prevError + "\nExecution halted and changes rolled back."
          );
        }
      } catch (parseError) {
        setError(`Parsing Error: ${parseError.message}`);
      }
    }
  };

  const handleSaveImage = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const link = document.createElement("a");
      link.download = "turtle_drawing.png";
      // Temporarily redraw without the turtle for saving? Optional.
      // For now, save with the turtle visible.
      link.href = canvas.toDataURL("image/png");
      link.click();
    }
  };

  // --- Render UI ---
  return (
    <div className="turtle-container">
      <h2 className="turtle-header">Enhanced Turtle Graphics</h2>

      <div className="turtle-main">
        {/* Wrap canvas in a div */}
        <div
          className="turtle-canvas"
          style={{ backgroundColor: canvasBgColor }} // Apply background to wrapper
        >
          <canvas
            ref={canvasRef}
            width="600" // Keep attributes for initial render size? Or rely solely on CSS? Let's keep for now.
            height="400"
            // className="turtle-canvas" // Class moved to wrapper
            // style={{ backgroundColor: canvasBgColor }} // Style moved to wrapper
          ></canvas>
        </div>
        <div className="turtle-history">
          <h3>Execution History:</h3>
          <ul>
            {history.map((item, index) => (
              <li key={index}>
                {item.type === "program" ? (
                  <pre>{item.content}</pre>
                ) : (
                  item.content
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <form onSubmit={handleFormSubmit} className="turtle-form">
        <textarea
          value={program}
          onChange={handleInputChange}
          placeholder="Enter turtle commands (e.g., fd 50; rt 90; repeat 4 [fd 100 rt 90])"
          className="turtle-input" // Use textarea for multi-line input
          rows="4" // Adjust height as needed
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <button type="submit" className="turtle-button">
            Execute
          </button>
          <button
            type="button"
            onClick={handleSaveImage}
            className="save-button"
          >
            Save Image
          </button>
          <button
            type="button"
            onClick={() => {
              // saveState(); // Don't save state before undoing
              executeSingleCommand({ name: "undo" });
            }}
            className="turtle-button"
            style={{ backgroundColor: "#ffc107", color: "black" }}
          >
            Undo
          </button>
          <button
            type="button"
            onClick={() => {
              // saveState(); // Don't save state before clearing (clear resets state anyway)
              executeSingleCommand({ name: "clear" });
              setHistory([]);
            }}
            className="turtle-button"
            style={{ backgroundColor: "#dc3545" }}
          >
            Clear All
          </button>
        </div>
      </form>

      {error && <div className="turtle-error">{error}</div>}

      {/* Updated Legend */}
      <div
        className="turtle-legend"
        style={{
          backgroundColor: "#222",
          color: "#eee",
          padding: "10px",
          borderRadius: "4px",
        }}
      >
        <h3>Commands (Case Insensitive)</h3>
        <div className="legend-columns">
          <div className="legend-column">
            <ul>
              <li>
                <code>fd N</code> / <code>forward N</code>
              </li>
              <li>
                <code>bk N</code> / <code>backward N</code>
              </li>
              <li>
                <code>rt N</code> / <code>right N</code>
              </li>
              <li>
                <code>lt N</code> / <code>left N</code>
              </li>
              <li>
                <code>goto X Y</code>
              </li>
              <li>
                <code>seth N</code> / <code>setheading N</code>
              </li>
              <li>
                <code>home</code>
              </li>
              <li>
                <code>pu</code> / <code>penup</code>
              </li>
              <li>
                <code>pd</code> / <code>pendown</code>
              </li>
            </ul>
          </div>
          <div className="legend-column">
            <ul>
              <li>
                <code>setcolor C</code> / <code>color C</code>
              </li>
              <li>
                <code>setwidth N</code> / <code>width N</code> /{" "}
                <code>pensize N</code>
              </li>
              <li>
                <code>ht</code> (hide turtle)
              </li>
              <li>
                <code>st</code> (show turtle)
              </li>
              <li>
                <code>dot [size] [color]</code>
              </li>
              <li>
                <code>stamp</code>
              </li>
              <li>
                <code>circle R</code>
              </li>
              <li>
                <code>arc Angle R</code>
              </li>
              <li>
                <code>beginfill</code> / <code>begin_fill</code>
              </li>
              <li>
                <code>endfill</code> / <code>end_fill</code>
              </li>
              <li>
                <code>clr</code> / <code>cs</code> / <code>clear</code> /{" "}
                <code>clearscreen</code>
              </li>
              <li>
                <code>setbgcolor C</code> / <code>bgcolor C</code>
              </li>
              <li>
                <code>speed N</code> (0-10, 0=fastest)
              </li>
              <li>
                <code>repeat N [ commands ]</code>
              </li>
              <li>
                <code>undo</code>
              </li>
              <li>
                <code># comment</code>
              </li>
              <li>
                Commands separated by newline or <code>;</code>
              </li>
            </ul>
          </div>
        </div>
        <p>
          <strong>Example:</strong>{" "}
          <code>setcolor blue; repeat 6 [ fd 50 rt 60 ]</code>
        </p>
        <p>
          <strong>Fill Example:</strong>{" "}
          <code>pd; beginfill; repeat 4 [fd 100 rt 90]; endfill</code>
        </p>
      </div>
    </div>
  );
};

export default Turtle;
