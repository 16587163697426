import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthContext"; // Used for logout on token error
import "./ManagePrompts.css";

const ManagePrompts = () => {
  const [prompts, setPrompts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false); // false = Add, true = Edit
  const [currentPrompt, setCurrentPrompt] = useState({
    id: null,
    display: "",
    value: "",
  });

  // Get logout function from context to handle token errors
  const { logout } = useContext(AuthContext);

  // --- NEW: Helper function to get auth token ---
  const getAuthToken = () => {
    const storedAuth = localStorage.getItem("authData");
    if (storedAuth) {
      try {
        const authData = JSON.parse(storedAuth);
        if (authData && authData.token && authData.expiration) {
          const expirationDate = new Date(authData.expiration);
          if (expirationDate > new Date()) {
            return authData.token; // Return the valid token
          } else {
            // Token expired
            localStorage.removeItem("authData");
            logout(); // Update auth state via context
            setError("Session expired. Please log in again.");
            return null;
          }
        }
      } catch (e) {
        console.error("Error parsing authData from localStorage:", e);
        localStorage.removeItem("authData");
      }
    }
    // No stored auth or invalid data
    logout(); // Ensure user is logged out if no valid token found
    setError("Authentication required. Please log in.");
    return null;
  };
  // --- END NEW HELPER ---

  // Fetch prompts on component mount
  useEffect(() => {
    fetchPrompts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once on mount

  const fetchPrompts = async () => {
    const token = getAuthToken(); // Use helper function
    if (!token) {
      // Error/logout handled within getAuthToken
      setPrompts([]); // Clear prompts if not authorized
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch("/api/custom_prompts", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          // Handle specific unauthorized error, likely token issue
          getAuthToken(); // This will trigger logout and set error
          setPrompts([]);
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setPrompts(data);
    } catch (e) {
      console.error("Failed to fetch prompts:", e);
      setError(`Failed to load prompts: ${e.message}`);
      setPrompts([]); // Clear prompts on error
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentPrompt((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditClick = (prompt) => {
    setEditMode(true);
    setCurrentPrompt({ ...prompt }); // Load prompt data into form
    window.scrollTo(0, 0); // Scroll to top to see the form
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setCurrentPrompt({ id: null, display: "", value: "" }); // Clear form
  };

  const handleDeleteClick = async (promptId) => {
    if (!window.confirm("Are you sure you want to delete this prompt?")) {
      return;
    }

    const token = getAuthToken(); // Use helper function
    console.log("Token retrieved for handleSubmit:", token); // ADD LOGGING
    if (!token) {
      // Error/logout handled within getAuthToken
      console.error("handleSubmit aborted: No valid token found."); // ADD LOGGING
      return;
    }
    setError(null); // Clear previous errors

    try {
      const response = await fetch(`/api/custom_prompts/${promptId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          getAuthToken(); // Trigger logout/error
          return;
        }
        const errorData = await response
          .json()
          .catch(() => ({ error: "Unknown error during delete" }));
        throw new Error(
          errorData.error || `HTTP error! status: ${response.status}`
        );
      }
      // Refresh prompt list after successful delete
      fetchPrompts();
      alert("Prompt deleted successfully."); // Simple feedback
    } catch (e) {
      console.error("Failed to delete prompt:", e);
      setError(`Failed to delete prompt: ${e.message}`);
      alert(`Error deleting prompt: ${e.message}`); // Simple feedback
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!currentPrompt.display.trim() || !currentPrompt.value.trim()) {
      alert("Both Display and Value fields are required.");
      return;
    }

    const token = getAuthToken(); // Use helper function
    if (!token) {
      // Error/logout handled within getAuthToken
      return;
    }
    setError(null); // Clear previous errors

    const url = editMode
      ? `/api/custom_prompts/${currentPrompt.id}`
      : "/api/custom_prompts";
    const method = editMode ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          display: currentPrompt.display,
          value: currentPrompt.value,
        }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          getAuthToken(); // Trigger logout/error
          return;
        }
        const errorData = await response
          .json()
          .catch(() => ({ error: "Unknown error during save" }));
        throw new Error(
          errorData.error || `HTTP error! status: ${response.status}`
        );
      }

      // Reset form and refresh list
      handleCancelEdit();
      fetchPrompts();
      alert(`Prompt ${editMode ? "updated" : "added"} successfully.`); // Simple feedback
    } catch (e) {
      console.error("Failed to save prompt:", e);
      setError(`Failed to save prompt: ${e.message}`);
      alert(`Error saving prompt: ${e.message}`); // Simple feedback
    }
  };

  return (
    <div className="manage-prompts-container">
      <h2>Manage Custom Prompts</h2>

      {/* Display general errors */}
      {error && <p className="error-message">Error: {error}</p>}

      {/* Add/Edit Form */}
      <form onSubmit={handleSubmit} className="prompt-form">
        <h3>{editMode ? "Edit Prompt" : "Add New Prompt"}</h3>
        <div className="form-group">
          <label htmlFor="display">Display Text:</label>
          <input
            type="text"
            id="display"
            name="display"
            value={currentPrompt.display}
            onChange={handleInputChange}
            placeholder="Text shown in dropdown (e.g., 3x3 Grid)"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="value">Prompt Template (Value):</label>
          <textarea
            id="value"
            name="value"
            value={currentPrompt.value}
            onChange={handleInputChange}
            placeholder='Template with {query} placeholder (e.g., Generate image of "{query}" in 3x3 grid)'
            rows="4"
            required
          />
          <small>
            Use {"{query}"} where the user's input should be inserted.
          </small>
        </div>
        <div className="form-actions">
          <button type="submit" className="btn btn-primary">
            {editMode ? "Update Prompt" : "Add Prompt"}
          </button>
          {editMode && (
            <button
              type="button"
              onClick={handleCancelEdit}
              className="btn btn-secondary"
            >
              Cancel Edit
            </button>
          )}
        </div>
      </form>

      <hr />

      <h3>Existing Prompts</h3>
      {isLoading && <p>Loading prompts...</p>}
      {/* Error is displayed above the form */}
      {!isLoading && !error && prompts.length === 0 && (
        <p>No custom prompts found.</p>
      )}
      {!isLoading &&
        prompts.length > 0 && ( // Render list even if there was an error previously
          <ul className="prompts-list">
            {prompts.map((prompt) => (
              <li key={prompt.id}>
                <div className="prompt-details">
                  <strong>{prompt.display}</strong>
                  <p>
                    <code>{prompt.value}</code>
                  </p>
                  <small>
                    Created: {new Date(prompt.created_at).toLocaleString()}
                  </small>
                </div>
                <div className="prompt-actions">
                  <button
                    onClick={() => handleEditClick(prompt)}
                    className="btn btn-edit"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteClick(prompt.id)}
                    className="btn btn-delete"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
    </div>
  );
};

export default ManagePrompts;
