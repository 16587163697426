import React, { useState, useEffect, useContext } from "react";
import "./Prompter.css";
import { AuthContext } from "./AuthContext"; // Used for login status check and logout

const Prompter = () => {
  const [query, setQuery] = useState("");
  const [queryInvalid, setQueryInvalid] = useState(false);
  const [humor, setHumor] = useState(3);
  const [englishLevel, setEnglishLevel] = useState(3);
  const [technicalDepth, setTechnicalDepth] = useState(3);
  const [length, setLength] = useState(3);
  const [tone, setTone] = useState(3);
  const [audience, setAudience] = useState(3);
  const [formality, setFormality] = useState(3);
  const [creativity, setCreativity] = useState(3);
  // REMOVED: structure state
  const [role, setRole] = useState(""); // NEW: Role Prompting
  const [enableCoT, setEnableCoT] = useState(false); // NEW: Chain of Thought
  const [outputFormat, setOutputFormat] = useState("Descriptive"); // NEW: Output Format (Default: Descriptive)
  const [generatedPrompt, setGeneratedPrompt] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null); // Store the whole selected option object
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true); // NEW: Flag for initial load

  // Get login status and logout function from context
  const { isLoggedIn, logout } = useContext(AuthContext);

  // --- NEW: Load settings from localStorage on initial mount ---
  useEffect(() => {
    const savedSettings = localStorage.getItem("prompterSettingsCache");
    if (savedSettings) {
      try {
        const settings = JSON.parse(savedSettings);

        // Check and set each value individually, ensuring the key exists
        if (
          settings.hasOwnProperty("humor") &&
          settings.humor >= 1 &&
          settings.humor <= 5
        ) {
          setHumor(settings.humor);
        }
        if (
          settings.hasOwnProperty("englishLevel") &&
          settings.englishLevel >= 1 &&
          settings.englishLevel <= 5
        ) {
          setEnglishLevel(settings.englishLevel);
        }
        if (
          settings.hasOwnProperty("technicalDepth") &&
          settings.technicalDepth >= 1 &&
          settings.technicalDepth <= 5
        ) {
          setTechnicalDepth(settings.technicalDepth);
        }
        if (
          settings.hasOwnProperty("length") &&
          settings.length >= 1 &&
          settings.length <= 5
        ) {
          setLength(settings.length);
        }
        if (
          settings.hasOwnProperty("tone") &&
          settings.tone >= 1 &&
          settings.tone <= 5
        ) {
          setTone(settings.tone);
        }
        if (
          settings.hasOwnProperty("audience") &&
          settings.audience >= 1 &&
          settings.audience <= 5
        ) {
          setAudience(settings.audience);
        }
        if (
          settings.hasOwnProperty("formality") &&
          settings.formality >= 1 &&
          settings.formality <= 5
        ) {
          setFormality(settings.formality);
        }
        if (
          settings.hasOwnProperty("creativity") &&
          settings.creativity >= 1 &&
          settings.creativity <= 5
        ) {
          setCreativity(settings.creativity);
        }
        if (
          settings.hasOwnProperty("role") &&
          typeof settings.role === "string"
        ) {
          setRole(settings.role);
        }
        if (
          settings.hasOwnProperty("enableCoT") &&
          typeof settings.enableCoT === "boolean"
        ) {
          setEnableCoT(settings.enableCoT);
        }
        if (
          settings.hasOwnProperty("outputFormat") &&
          OUTPUT_FORMAT_OPTIONS.includes(settings.outputFormat)
        ) {
          setOutputFormat(settings.outputFormat);
        }
      } catch (e) {
        console.error(
          "Failed to parse prompter settings from localStorage:",
          e
        );
        localStorage.removeItem("prompterSettingsCache"); // Clear invalid data
      }
    }
    setIsInitialLoad(false); // Mark initial load as complete
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs only once on mount

  // --- NEW: Save settings to localStorage whenever they change ---
  useEffect(() => {
    // Only save if it's not the initial load phase
    if (!isInitialLoad) {
      const settingsToSave = {
        humor,
        englishLevel,
        technicalDepth,
        length,
        tone,
        audience,
        formality,
        creativity,
        role,
        enableCoT,
        outputFormat,
      };
      localStorage.setItem(
        "prompterSettingsCache",
        JSON.stringify(settingsToSave)
      );
    }
  }, [
    humor,
    englishLevel,
    technicalDepth,
    length,
    tone,
    audience,
    formality,
    creativity,
    role,
    enableCoT,
    outputFormat,
    isInitialLoad, // Add isInitialLoad to dependencies
  ]); // Dependency array includes all settings EXCEPT query

  // --- NEW: Helper function to get auth token ---
  const getAuthToken = () => {
    const storedAuth = localStorage.getItem("authData");
    if (storedAuth) {
      try {
        const authData = JSON.parse(storedAuth);
        if (authData && authData.token && authData.expiration) {
          const expirationDate = new Date(authData.expiration);
          if (expirationDate > new Date()) {
            return authData.token; // Return the valid token
          } else {
            // Token expired
            localStorage.removeItem("authData");
            logout(); // Update auth state via context
            // Don't set error here, let the fetch call handle it if needed
            return null;
          }
        }
      } catch (e) {
        console.error("Error parsing authData from localStorage:", e);
        localStorage.removeItem("authData");
      }
    }
    // No stored auth or invalid data
    // Don't trigger logout/error here, let the fetch call handle unauthorized
    return null;
  };
  // --- END NEW HELPER ---

  const HUMOR_MAP = {
    1: "None",
    2: "Light",
    3: "Moderate",
    4: "High",
    5: "Sarcastic",
  };
  const ENGLISH_LEVEL_MAP = {
    1: "B1",
    2: "B2",
    3: "C1",
    4: "C2",
    5: "Native",
  };
  const TECHNICAL_DEPTH_MAP = {
    1: "Beginner",
    2: "Low",
    3: "Medium",
    4: "High",
    5: "Expert",
  };
  const LENGTH_MAP = {
    1: "XS",
    2: "S",
    3: "M",
    4: "L",
    5: "XL",
  };
  const TONE_MAP = {
    1: "Casual",
    2: "Friendly",
    3: "Professional",
    4: "Authoritative",
    5: "Academic",
  };
  const AUDIENCE_MAP = {
    1: "Children",
    2: "General",
    3: "Business",
    4: "Technical",
    5: "Experts",
  };
  const FORMALITY_MAP = {
    1: "Informal",
    2: "Conversational",
    3: "Standard",
    4: "Formal",
    5: "Academic",
  };
  const CREATIVITY_MAP = {
    1: "Conservative",
    2: "Balanced",
    3: "Creative",
    4: "Highly Creative",
    5: "Experimental",
  };
  // REMOVED: STRUCTURE_MAP

  // NEW: Output Format Options (Merged with Structure)
  const OUTPUT_FORMAT_OPTIONS = [
    "Descriptive", // Default
    "Narrative",
    "List",
    "Bullet Points",
    "Detailed Outline",
    "JSON",
    "Markdown",
    "Default Text", // Keep as fallback/option
  ];

  const LENGTH_CHARS = {
    xs: 1000,
    s: 2000,
    m: 5000,
    l: 8000,
    xl: 10000,
  };

  // Helper to compute slider background style
  const getSliderStyle = (value) => {
    const percentage = ((value - 1) / 4) * 100;
    return {
      background: `linear-gradient(90deg, #28a745 ${percentage}%, #555 ${percentage}%)`,
    };
  };

  // --- generatePrompt Function (Button Click) ---
  // ALWAYS generates based on sliders and updates the state/clipboard
  const generatePrompt = () => {
    // 1. Validate query
    if (!query.trim()) {
      setQueryInvalid(true);
      setGeneratedPrompt(""); // Clear prompt display if query is invalid
      return;
    } else {
      setQueryInvalid(false);
    }

    // 2. ALWAYS calculate prompt based on sliders
    const promptParts = [
      `Generate content about "${query}".`,
      getHumorSentence(humor),
      getEnglishSentence(englishLevel),
      getTechnicalSentence(technicalDepth),
      getLengthSentence(length),
      getToneSentence(tone),
      getAudienceSentence(audience),
      getFormalitySentence(formality),
      getCreativitySentence(creativity),
      // REMOVED: getStructureSentence(structure),
      // NEW: Add Role if specified
      role.trim() ? `Act as a ${role.trim()}.` : "",
      // UPDATED: Add Output Format/Structure instruction
      getOutputFormatSentence(outputFormat),
      "Ensure that the final content is well-structured, coherent, and aligns with these guidelines.",
      // NEW: Add CoT instruction if enabled
      enableCoT ? "Let's think step by step." : "",
    ];
    const sliderGeneratedPrompt = promptParts // Assign the result here
      .filter((part) => part)
      .join(" "); // Filter out empty strings before joining

    // 3. Update the state with the slider-generated prompt
    setGeneratedPrompt(sliderGeneratedPrompt);

    // --- ADDED: Reset dropdown selection ---
    setSelectedOption(null);
    // --- END ADDED ---

    // 4. Copy the slider-generated prompt to clipboard
    navigator.clipboard
      .writeText(sliderGeneratedPrompt)
      .then(() => {
        showNotification("Prompt copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy prompt: ", err);
        showNotification("Failed to copy prompt.", true);
      });
  };
  // --- End of generatePrompt Function ---

  const getHumorSentence = (value) => {
    if (value === 1)
      return "The content should be completely serious with no hint of humor.";
    if (value === 2) return "It should feature a subtle, light touch of humor.";
    if (value === 3)
      return "Incorporate a moderate dose of humor to keep it engaging.";
    if (value === 4)
      return "Infuse the content with a high level of humor to entertain the audience.";
    if (value === 5)
      return "The content should be saturated with sarcastic wit and humor.";
  };

  const getEnglishSentence = (value) => {
    if (value === 1)
      return "Use simple, everyday English suitable for B1 learners.";
    if (value === 2)
      return "Employ clear and straightforward language, appropriate for B2 proficiency.";
    if (value === 3)
      return "Demonstrate an advanced command of English at the C1 level.";
    if (value === 4)
      return "Utilize sophisticated language reflecting near-fluency at the C2 level.";
    if (value === 5)
      return "Express ideas with native-level fluency and nuance.";
  };

  const getTechnicalSentence = (value) => {
    if (value === 1)
      return "Keep technical explanations very basic, ideal for beginners.";
    if (value === 2)
      return "Present technical details in a simple and accessible manner.";
    if (value === 3)
      return "Offer a balanced level of technical detail that is neither too basic nor too advanced.";
    if (value === 4)
      return "Delve into technical aspects with considerable depth and complexity.";
    if (value === 5)
      return "Provide expert-level technical insights and a deep analytical perspective.";
  };

  const getLengthSentence = (value) => {
    const lengthKey = LENGTH_MAP[Math.round(value)].toLowerCase(); // Convert key to lowercase
    const charLimit = LENGTH_CHARS[lengthKey];
    if (value === 1)
      return `Keep the content succinct, with a minimum of ${charLimit} characters.`;
    if (value === 2)
      return `Ensure the content is fairly concise, containing at least ${charLimit} characters.`;
    if (value === 3)
      return `Craft content of moderate length, with no less than ${charLimit} characters.`;
    if (value === 4)
      return `Develop comprehensive content, requiring at least ${charLimit} characters.`;
    if (value === 5)
      return `Generate expansive and detailed content, with a minimum of ${charLimit} characters.`;
  };

  const getToneSentence = (value) => {
    if (value === 1) return "Adopt a casual and relaxed tone.";
    if (value === 2) return "Use a friendly and approachable tone.";
    if (value === 3)
      return "Maintain a professional tone that conveys expertise.";
    if (value === 4)
      return "Adopt an authoritative tone that inspires confidence.";
    if (value === 5)
      return "Embrace an academic tone with formal language and precision.";
  };

  const getAudienceSentence = (value) => {
    if (value === 1)
      return "Tailor the content to be engaging and accessible for children.";
    if (value === 2)
      return "Ensure the content is easily understood by a general audience.";
    if (value === 3)
      return "Design the content with a business audience in mind, focusing on clarity.";
    if (value === 4)
      return "Cater the content to a technical audience by incorporating relevant jargon.";
    if (value === 5)
      return "Target experts by providing in-depth analysis and detailed insights.";
  };

  const getFormalitySentence = (value) => {
    if (value === 1) return "Keep the language informal and relaxed.";
    if (value === 2)
      return "Adopt a conversational style that strikes a balance between casual and formal.";
    if (value === 3)
      return "Maintain a standard level of formality that is professional yet accessible.";
    if (value === 4)
      return "Use a formal style, ensuring the language is polished and refined.";
    if (value === 5)
      return "Craft the content with a highly formal and academic tone.";
  };

  const getCreativitySentence = (value) => {
    if (value === 1)
      return "The approach should be conservative and straightforward.";
    if (value === 2)
      return "Strike a balanced blend of creativity and structure.";
    if (value === 3)
      return "Incorporate creative and original ideas into the content.";
    if (value === 4)
      return "Push the boundaries with a highly creative approach.";
    if (value === 5)
      return "Adopt an experimental and avant-garde style, exploring unconventional ideas.";
  };

  // REMOVED: getStructureSentence function definition

  // UPDATED: Function to generate output format/structure sentence
  const getOutputFormatSentence = (format) => {
    switch (format) {
      // Structure options
      case "Narrative":
        return "The content should follow a narrative structure, telling a cohesive story.";
      case "Descriptive":
        return "It should be descriptive, painting a vivid picture with detailed prose.";
      case "List":
        return "Organize the content as a structured list for clarity.";
      case "Bullet Points":
        return "Use bullet points to organize the content clearly.";
      case "Detailed Outline":
        return "Develop a detailed outline that systematically presents the ideas.";
      // Format options
      case "JSON":
        return "Format the final output as a valid JSON object.";
      case "Markdown":
        return "Use Markdown formatting for the final output.";
      // Default / Fallback
      case "Default Text":
      default:
        return ""; // No specific instruction for default/descriptive if it's the base
    }
  };

  const showNotification = (message, isError = false) => {
    const notif = document.createElement("div");
    notif.className = "notification";
    notif.textContent = message;
    notif.style.position = "fixed";
    notif.style.top = "20px";
    notif.style.left = "50%";
    notif.style.transform = "translateX(-50%)";
    notif.style.zIndex = "10000";
    if (isError) {
      notif.style.backgroundColor = "#dc3545";
    }
    document.body.appendChild(notif);
    setTimeout(() => {
      notif.classList.add("fade-out");
      setTimeout(() => {
        document.body.removeChild(notif);
      }, 500);
    }, 1000);
  };

  // --- Updated useEffect for Fetching ---
  useEffect(() => {
    if (window.location.pathname !== "/prompter") {
      window.history.pushState({}, "", "/prompter");
    }
    // Fetch dropdown options ONLY if logged in
    if (isLoggedIn) {
      const token = getAuthToken(); // Use helper function
      if (!token) {
        // If no token (e.g., expired during session), clear options
        setDropdownOptions([]);
        setSelectedOption(null);
        return;
      }

      setIsLoading(true);
      setError(null); // Reset error on new fetch attempt
      fetch("/api/custom_prompts", {
        headers: {
          Authorization: `Bearer ${token}`, // Send token
        },
      })
        .then((response) => {
          if (response.status === 401) {
            getAuthToken(); // This will trigger logout if token is invalid/expired
            setDropdownOptions([]);
            setSelectedOption(null);
            throw new Error("Unauthorized"); // Stop further processing
          }
          if (!response.ok) {
            throw new Error(
              "Network response was not ok fetching custom prompts"
            );
          }
          return response.json();
        })
        .then((data) => {
          // Assuming data is an array of { display: string, value: string }
          setDropdownOptions([
            { display: "Select Custom Prompt...", value: "" },
            ...data,
          ]); // Add default option
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.message !== "Unauthorized") {
            // Don't show generic error if it was auth
            setError(error.message);
            console.error("Failed to fetch custom prompts:", error);
            showNotification("Failed to load custom prompts.", true);
          }
          // Keep mock data as fallback? Or show empty? Showing empty for now.
          setDropdownOptions([
            { display: "Select Custom Prompt...", value: "" },
          ]);
          setIsLoading(false); // Ensure loading is set to false on error
        });
    } else {
      // Clear options if user logs out
      setDropdownOptions([]);
      setSelectedOption(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]); // Re-run if login status changes
  // --- End of Updated useEffect ---

  // --- useEffect Hook for Dropdown Interaction ---
  // Updates generatedPrompt ONLY when dropdown selection changes
  useEffect(() => {
    if (selectedOption && selectedOption.value) {
      // A valid dropdown option is selected (not the default)
      if (query.trim()) {
        // Query is also valid, generate the dynamic prompt and display it
        const dynamicPrompt = selectedOption.value.replace("{query}", query);
        setGeneratedPrompt(dynamicPrompt);
        setQueryInvalid(false); // Query is valid in this case
      } else {
        // Dropdown selected, but query is empty. Clear the display.
        setGeneratedPrompt("");
      }
    } else if (selectedOption && !selectedOption.value) {
      // The "Select..." option was chosen, clear the display
      setGeneratedPrompt("");
    }
    // If selectedOption is null (initial state or cleared),
    // ensure the slider-based prompt is regenerated if query exists
    else if (!selectedOption && query.trim()) {
      // Re-trigger generation based on sliders if dropdown is deselected
      // This logic is now handled by the generatePrompt button click
      // We might want to clear the prompt display instead when dropdown is deselected
      setGeneratedPrompt(""); // Clear prompt when dropdown is deselected
    }
  }, [selectedOption, query]); // Rerun when dropdown or query changes
  // --- End of useEffect Hook ---

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    // Find the full option object based on the selected value
    const option = dropdownOptions.find((opt) => opt.value === selectedValue);
    setSelectedOption(option); // Store the full option object
    // The useEffect hook will now handle updating the generatedPrompt display
  };

  return (
    <div>
      <div
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ margin: 0 }}>Prompt Generator</h2>
        <a
          href="/"
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            marginLeft: "auto",
          }}
        >
          Back to Home
        </a>
      </div>
      <div className="prompter-container">
        <p className="description">
          Customize your prompt by selecting options for each property and
          entering your query.
        </p>
        <div className="query-input">
          <label htmlFor="query">Enter your query:</label>
          <textarea
            id="query"
            rows="3"
            placeholder="What topic would you like content about?"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            style={
              queryInvalid
                ? { width: "100%", border: "1px solid red" }
                : { width: "100%" }
            }
          ></textarea>
        </div>

        {/* --- NEW: Role, CoT, Format Inputs --- */}
        {/* Disable these if a custom prompt is selected */}
        <div
          className="enhancement-inputs"
          style={{
            display: "flex",
            gap: "20px",
            marginBottom: "20px",
            flexWrap: "wrap",
          }}
        >
          <div className="input-group" style={{ flex: "1 1 200px" }}>
            <label htmlFor="role-input">Desired Role (Optional):</label>
            <input
              type="text"
              id="role-input"
              placeholder="e.g., Expert Historian, Witty Comedian"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              disabled={!!selectedOption?.value} // Disable if custom prompt selected
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
          </div>
          <div className="input-group" style={{ flex: "1 1 150px" }}>
            <label htmlFor="output-format-select">Output Format:</label>
            <select
              id="output-format-select"
              value={outputFormat}
              onChange={(e) => setOutputFormat(e.target.value)}
              disabled={!!selectedOption?.value} // Disable if custom prompt selected
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            >
              {OUTPUT_FORMAT_OPTIONS.map((format) => (
                <option key={format} value={format}>
                  {format}
                </option>
              ))}
            </select>
          </div>
          <div
            className="input-group"
            style={{
              display: "flex",
              alignItems: "center",
              flexBasis: "180px",
            }}
          >
            <input
              type="checkbox"
              id="cot-checkbox"
              checked={enableCoT}
              onChange={(e) => setEnableCoT(e.target.checked)}
              disabled={!!selectedOption?.value} // Disable if custom prompt selected
              style={{ marginRight: "8px" }}
            />
            <label htmlFor="cot-checkbox">Enable Chain of Thought</label>
          </div>
        </div>
        {/* --- END NEW Inputs --- */}

        <div className="properties-container">
          {/* Slider Groups */}
          {/* Add disabled={!!selectedOption?.value} to all slider inputs */}
          <div className="slider-group">
            <label htmlFor="humor-slider">
              Humor Level:{" "}
              <span className="slider-value">{HUMOR_MAP[humor]}</span>
            </label>
            <input
              type="range"
              id="humor-slider"
              min="1"
              max="5"
              step="1"
              value={humor}
              onChange={(e) => setHumor(parseInt(e.target.value, 10))}
              className="slider"
              style={getSliderStyle(humor)}
              disabled={!!selectedOption?.value}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="english-slider">
              English Level:{" "}
              <span className="slider-value">
                {ENGLISH_LEVEL_MAP[englishLevel]}
              </span>
            </label>
            <input
              type="range"
              id="english-slider"
              min="1"
              max="5"
              step="1"
              value={englishLevel}
              onChange={(e) => setEnglishLevel(parseInt(e.target.value, 10))}
              className="slider"
              style={getSliderStyle(englishLevel)}
              disabled={!!selectedOption?.value}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="tech-slider">
              Technical Depth:{" "}
              <span className="slider-value">
                {TECHNICAL_DEPTH_MAP[technicalDepth]}
              </span>
            </label>
            <input
              type="range"
              id="tech-slider"
              min="1"
              max="5"
              step="1"
              value={technicalDepth}
              onChange={(e) => setTechnicalDepth(parseInt(e.target.value, 10))}
              className="slider"
              style={getSliderStyle(technicalDepth)}
              disabled={!!selectedOption?.value}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="length-slider">
              Length: <span className="slider-value">{LENGTH_MAP[length]}</span>
            </label>
            <input
              type="range"
              id="length-slider"
              min="1"
              max="5"
              step="1"
              value={length}
              onChange={(e) => setLength(parseInt(e.target.value, 10))}
              className="slider"
              style={getSliderStyle(length)}
              disabled={!!selectedOption?.value}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="tone-slider">
              Tone: <span className="slider-value">{TONE_MAP[tone]}</span>
            </label>
            <input
              type="range"
              id="tone-slider"
              min="1"
              max="5"
              step="1"
              value={tone}
              onChange={(e) => setTone(parseInt(e.target.value, 10))}
              className="slider"
              style={getSliderStyle(tone)}
              disabled={!!selectedOption?.value}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="audience-slider">
              Target Audience:{" "}
              <span className="slider-value">{AUDIENCE_MAP[audience]}</span>
            </label>
            <input
              type="range"
              id="audience-slider"
              min="1"
              max="5"
              step="1"
              value={audience}
              onChange={(e) => setAudience(parseInt(e.target.value, 10))}
              className="slider"
              style={getSliderStyle(audience)}
              disabled={!!selectedOption?.value}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="formality-slider">
              Formality:{" "}
              <span className="slider-value">{FORMALITY_MAP[formality]}</span>
            </label>
            <input
              type="range"
              id="formality-slider"
              min="1"
              max="5"
              step="1"
              value={formality}
              onChange={(e) => setFormality(parseInt(e.target.value, 10))}
              className="slider"
              style={getSliderStyle(formality)}
              disabled={!!selectedOption?.value}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="creativity-slider">
              Creativity:{" "}
              <span className="slider-value">{CREATIVITY_MAP[creativity]}</span>
            </label>
            <input
              type="range"
              id="creativity-slider"
              min="1"
              max="5"
              step="1"
              value={creativity}
              onChange={(e) => setCreativity(parseInt(e.target.value, 10))}
              className="slider"
              style={getSliderStyle(creativity)}
              disabled={!!selectedOption?.value}
            />
          </div>
          {/* REMOVED: Structure Slider Group */}
        </div>
        {/* --- Updated Action Row for Centering --- */}
        <div
          className="action-row"
          style={{
            display: "flex",
            justifyContent: "center", // Center items horizontally
            alignItems: "center",
            gap: "15px", // Add some space between button and dropdown
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <button
            className="generate-button"
            onClick={generatePrompt}
            disabled={isLoading} // Disable button while dropdown loads
            style={{ margin: 0 }} // Remove auto margin from button
          >
            Generate Prompt
          </button>
          {/* --- Conditionally render dropdown based on login and loading state --- */}
          {isLoggedIn &&
            !isLoading &&
            dropdownOptions.length > 1 && ( // Only show if logged in, not loading, and has more than default option
              <select
                className="custom-prompt-dropdown" // Use class for styling
                onChange={handleDropdownChange}
                value={selectedOption ? selectedOption.value : ""} // Control component value
              >
                {dropdownOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.display}
                  </option>
                ))}
              </select>
            )}
          {isLoggedIn && isLoading && <span>Loading prompts...</span>}
          {isLoggedIn && !isLoading && error && (
            <span style={{ color: "red" }}>Error loading prompts</span>
          )}
          {/* --- End of Conditional Rendering --- */}
        </div>
        {/* --- End of Updated Action Row --- */}
        {generatedPrompt && (
          <div className="generated-prompt">
            <h2>Generated Prompt:</h2>
            <div className="prompt-box">{generatedPrompt}</div>
          </div>
        )}
      </div>
      <div className="footer">
        <p></p>
      </div>
    </div>
  );
};

export default Prompter;
